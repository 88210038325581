.form-height {
  height: 100%;
}

.ui.stacked.segment.form-fields {
  border: 0;
  box-shadow: none;
  background: rgba(255, 255, 255, 0) !important;
}

.main-form .ui.stacked.segment:after {
  border: 0;
  height: 0;
}

.main-form .ui.vertical.segment {
  padding-top: 0px;
}

.main-form h1 {
  font-family: reader-black-pro, Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
}

.main-form p, h5 {
  font-family: reader-regular, Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
  font-weight: normal;
}

.main-form .ui.form .field > label {
  color: #bc1f2f;
  text-align: left;
  font-weight: 300;
  font-size: 13px;
}

.pusher .pages .main-form { /*targets form on mobile*/
  padding-top: 14px;
}

.ui.checkbox input, 
.ui.checkbox input:hover, 
.ui.checkbox input:focus, 
.ui.checkbox input:active, 
.ui.checkbox input:focus~label {
  color: #bc1f2f;
}

.ui.checkbox label, 
.ui.checkbox label:hover, 
.ui.checkbox label:focus, 
.ui.checkbox label:active  {
  color: #bc1f2f;
}

.ui.checkbox label a {
  color: #bc1f2f;
  text-decoration: underline;
}

.main-form .ui.form .field .ui.input input,
.main-form .ui.form .fields .field .ui.input input {
  background: rgba(255, 255, 255, 0);
  border: 1px solid #bc1f2f;
  border-radius: 0;
  height: 38px;
}

.main-form .ui.form .two.fields .location-worked {
  width: 100%;
}

.main-form .ui.form textarea {
  border: 1px solid #bc1f2f;
  border-radius: 0;
}

.main-form .ui.form .field.dropdown-select .default.text {
  color: white;
}

.main-form .ui.form .field.dropdown-select .selection.dropdown {
  background: #bc1f2f;
  color: white;
  border-radius: 46px;
}

.main-form .ui.small.button.profile-buttons {
  border: 1px solid #bc1f2f;
  background: rgba(255, 255, 255, 0);
  border-radius: 2em;
  padding-top: .45em;
  padding-bottom: .45em;
  color: #bc1f2f;
  font-weight: 300;
  margin-top: 30px;
  line-height: unset;
}

.main-form .forms-section {
  padding-top: 30px;
  margin-left: 50px;
  margin-right: 50px;
}

/*Pic Upload*/
body {
  background-color: #ffffff;
}

.file-upload {
  background-color: #ffffff;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  margin-top: 20px;
  border: 3px solid #bc1f2f;
  position: relative;
}

.image-dropping,
.image-upload-wrap:hover {
  background-color: #bc1f2f;
  border: 3px solid #ffffff;
  color: #fff;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.drag-text {
  text-align: center;
}

.drag-text h3 {
  font-weight: 100;
  text-transform: uppercase;
  color: #bc1f2f;
  padding: 60px 0;
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}

.remove-image {
  width: 200px;
  margin: 0;
  color: #fff;
  background: #cd4535;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #b02818;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.remove-image:hover {
  background: #c13b2a;
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer;
}

.remove-image:active {
  border: 0;
  transition: all 0.2s ease;
}

.center-align {
  text-align: center;
}

.ui.progress.progress-bar {
  background: #fff;
  border: 1px solid #bc1f2f;
  max-width: 25%;
  margin: auto;
}

.profile-images {
  text-align: left;
  padding-top: 25px;
}
.profile-images .ui.label {
  color: #bc1f2f;
  background-color: transparent;
}

.required-subhead {
  font-size: 10px;
  color: #bc1f2f;
  text-align: left;
}

.progress-button-section {
  margin-top: 40px;
  text-align: center;
}

.social-add-button .ui.button {
  background: transparent;
  font-size: 25px;
  padding: 0;
  color: #bc1f2f;
}

.profile-image-headers {
  font-size: 13px;
  color: #bc1f2f;
  margin-right: 119px;
  margin-bottom: 4px;
}

.start-profile-images {
  margin: auto;
  height: 250px;
  width: 250px;
  border: 1px solid #bc1f2f;
}

.profile-image-additionals {
  padding-left: 70px;
  padding-right: 19px;
}

.profile-image-additionals .ui.labeled.icon.button,
.ui.labeled.icon.buttons .button {
  background: transparent;
  color: #bc1f2f;
  padding-left: 30px !important;
}

.profile-image-additionals .ui.labeled.icon.button > .icon,
.ui.labeled.icon.buttons > .button > .icon {
  background: transparent;
}

.image-upload-container {
  max-height: 225px;
  max-width: 225px;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.image-upload-container .ui.basic.label {
  border-radius: 0;
}

.image-upload {
  height: 300px;
  width: 100%;
  background: transparent !important;
  cursor: pointer;
  border: 1px solid #bc1f2f !important;
}

.image-upload::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.image-upload-button {
  background: transparent !important;
  margin-top: 40% !important;
}

.image-upload-button.icon {
  background: transparent;
  border: 0;
  color: #bc1f2f;
  font-size: 30px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.upload-text {
  font-size: 10px;
  margin-right: 113px;
}

.upload-being-text {
  font-size: 10px;
  margin-right: 113px;
  max-width: 185px;
  text-align: justify;
  margin-left: 109px;
  padding-top: 30px;
}

/* StepTwo Select */
.main-form .field.social-select {
  padding-left: 7px;
}

.main-form .social-select .ui.selection.dropdown {
  background-color: #bc1f2f;
  border-color: #bc1f2f;
  min-width: 125px;
  padding-left: 14px;
  border-radius: 2em;
  padding-top: .45em;
  padding-bottom: .45em;
  font-weight: 300;
  line-height: unset;
}
.main-form .ui.selection.dropdown i.icon {
  padding-bottom: 25px;
}

.main-form .ui.labels.podcasts .ui.label {
  font-weight: normal;
}

.pages .ui.selection.active.dropdown .menu {
  border-color: rgb(250, 250, 250) !important;
  background: rgb(250, 250, 250) !important;
}

.pages .ui.selection.dropdown .menu > .item {
  border: none !important;
  color: #323e48 !important;
}

.address-typeahead {
  margin-bottom: 14px;
  border-color: #bc1f2f;
}

.address-typeahead > div > input.prompt {
  border-radius: 0 !important;
  border-color: #bc1f2f !important;
}

.podcasts {
  text-align: left !important;
}
.main-form .forms-section.step-one .ui.form textarea,
.edit-profile .forms-section .ui.form textarea {
  min-height: 102px;
}

.image-upload-container {
  max-height: 225px;
  max-width: 225px;
}

.image-upload {
  height: 225px;
  width: 225px;
}

.edit-profile .forms-section.step-two,
.edit-profile .forms-section.step-two .row {
  padding-top: 0;
}

.edit-profile h1,
.edit-profile p,
.edit-profile .sub.header,
.edit-profile label,
.edit-profile .ui.form .required.field>label:after,
.edit-profile .image-upload-button.icon,
.edit-profile .ui.small.button.profile-buttons,
.edit-profile .ui.icon.button {
  color: #323e48 !important;
}

.edit-profile .ui.form .fields .field .ui.input input,
.edit-profile .ui.form .field .ui.input input,
.edit-profile .ui.basic.label.image-upload,
.edit-profile .ui.form textarea,
.edit-profile .ui.small.button.profile-buttons {
  border: 1px solid #323e48 !important;
}

.edit-profile .address-typeahead > div > input.prompt {
  border-color: #323e48 !important
}

.edit-profile .social-select .ui.selection.dropdown,
.edit-profile .ui.labels.podcasts .ui.label {
  background-color: #353839;
  border-color: #353839;
}

.pusher .pages .ui.form .fields {
  margin-bottom: 1em;
}

.pusher .pages #startYear {
  margin-bottom: 1em;
}

.pusher .pages .field.social-select {
  margin-bottom: 1em;
}

.ui.search.address-typeahead input {
  height: 38px;
}

/*media queries*/
@media only screen and (max-width: 769px) {
  .ui.container.create-profile-header {
    margin-left: 0!important;
    margin-right: 0!important;
  }
  .ui.stacked.segment.form-fields {
    padding: 0;
  }
  .ui.container>.ui.stackable.grid>.row>.column {
    padding-bottom: 0!important
  }
  .main-form .image-upload-container {
    margin-bottom: 14px;
  }
  .ui.container>.ui.stackable.grid>.row>.column.remove-pad-mobile {
    padding-top: 0px!important;
  }
  .social-select {
    margin-bottom: 8px;
  }
  .main-form .mandatory-print {
    width: 100%!important;
  }
  .main-form .mandatory-print p {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 989px) {
  .hide-checkbox-on-mobile {
    display: none;
  }
  .show-checkbox-on-mobile {
    display: block;
  }
  .ui.form .field.show-checkbox-on-mobile {
    margin-top: 1em;
  }
}

@media only screen and (min-width: 990px) {
  .show-checkbox-on-mobile {
    display: none;
  }
}
