.nav-page .navigation-menus {
  margin-top: 20px;
}

.nav-page .desktop-navigation {
  padding-bottom: 35px;
}

.desktop-navigation.welcome-form {
  padding-top: 25px;
}

.desktop-navigation .item.inner-nav-logo {
  padding: 0px;
  width: 100px;
  text-align: center;
}

.desktop-navigation .inner-nav-logo svg {
  display: inline-block;
  vertical-align: middle;
  margin: -2.3em 0;
  width: 13em
}

.desktop-navigation .site-menu {
  width: 100%;
}

.desktop-navigation .right.floated.column.site-menu {
  padding-right: .19rem;
}

.desktop-navigation .site-menu .search-menu-item .ui.input {
  width: 100%;
}

.desktop-navigation .site-menu .search-menu-item .ui.input > input {
  border: 0;
  padding-top: 0.578571em;
}

.desktop-navigation .right.floated.column.site-menu {
  padding-top: 0;
}

.desktop-navigation .ui.secondary.pointing.menu .active.item {
  border-color: white;
  color: #bc1f2f;
  font-weight: normal;
}

.desktop-navigation .ui.menu .item > .label {
  background: transparent;
  color: #bc1f2f;
  font-size: 1.06em;
  padding-left: 0.885714em;
  margin-left: 0px;
  padding-right: 0px;
}

.desktop-navigation .ui.image.label .detail {
  background: transparent;
}

.desktop-navigation .ui.label > .detail .icon {
  color: #ababab;
  font-size: 1.2em;
}

.desktop-navigation .ui.icon.menu {
  justify-content: flex-end;
}

.desktop-navigation .ui.secondary.pointing.menu {
  justify-content: flex-end;
}

.desktop-navigation .ui.secondary.pointing.menu .item {
  padding-right: 1.65em;
  padding-left: .65em;
  padding-bottom: 26px;
  padding-top: 10px;
}

.desktop-navigation .site-menu .search-menu-item.fixed-search-width .ui.input {
  width: 280px;
}

.desktop-navigation .search-menu-item.fixed-search-width input{
  height: 54.08px;
}

.desktop-navigation .search-menu-item.fixed-search-width .ui.input > i {
  margin-right: 17.143px;
}

.desktop-navigation .right.floated.column.desktop-social-settings-menu {
  padding-bottom: 0px;
  padding-right: .19rem;
}

.desktop-navigation .desktop-social-settings-menu .ui.icon.menu {
  border: 0;
  box-shadow: none;
}

.desktop-social-settings-menu .ui.menu .item {
  padding-bottom: 1px;
  padding-left: 0px;
  margin-left: 0px !important;
}

.desktop-social-settings-menu .ui.menu .item:hover {
  background: none;
}

.desktop-navigation .desktop-social-settings-menu .ui.menu .item > i.icon {
  font-size: 1.3em;
  color: #ababab;
  margin-right: -5px;
}

.desktop-navigation .item.setting-menu-item {
  margin-left: 3.84px !important;
  padding-right: 0px;
  min-width: 160px;
  justify-content: space-between;
  padding-left: 0px;
}

.desktop-navigation .setting-menu-item .ui.image.label .settings-profile-image {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 2.2rem;
  width: 2.2rem;
}

.desktop-navigation .ui.image.label img {
  border-radius: 0;
  width: 3rem !important;
}

.setting-menu-item .ui.item.dropdown {
  padding: 0px 5px 0px 0px;
}

.desktop-social-settings-menu .item.social-menu-item {
  padding-right: 20px;
}

.desktop-navigation .ui.fixed.menu, .ui[class*="top fixed"].menu {
  left: unset;
  right: 0;
}

.desktop-navigation .ui.menu.fixed {
  width: unset;
}
.desktop-navigation .ui.menu.fixed .inner-nav-logo {
  width: calc(100vw - 876.53px);
  text-align: left;
  padding-left: 20px;
}


.social-menu-item:before,
.setting-menu-item:before {
  width: 0 !important;
  background: none !important;
  height: 0;
}

.ui.menu .item.setting-menu-item .ui.item:before {
  height: 0 !important;
  width: 0px !important;
  background: none !important;
}

.desktop-social-settings-menu
  .ui.icon.menu
  .item.facebook-menu-item:before {
  width: 1px;
  height: 2.2em;
  top: 17px;
  background: rgba(34,36,38,.15);
}

.desktop-navigation .ui.secondary.pointing.menu .search-menu-item:before{
  content: '';
  width: 1px;
  height: 80%;
  top: 4px;
  background: rgba(34,36,38,.15);
  display: block;
  position: absolute;
  z-index: 1;
}

.desktop-navigation .ui.secondary.pointing.menu a.item:active {
  border-color: transparent;
}

.desktop-social-settings-menu .ui.dropdown>.text {
  display: inline-block;
  transition: none;
  margin-right: 11px;
  color: #BC1F2F;
  font-size: 1.06em;
}

.desktop-navigation .navigation-menu-divider {
  width: 747px;
  margin-right: 0;
  margin-bottom: 0;
  margin-top: 0;
  border-top: rgba(34,36,38,.15);
  color: rgba(0,0,0,.85);
}

.ui.secondary.inverted.pointing.menu {
  border: 0;
}

.mobile-global-search-bar .results,
.desktop-global-search .results {
  overflow: auto;
  max-height: 50vh;
}

.desktop-global-search.ui.category.search .results,
.mobile-global-search-bar.ui.category.search .results {
  width: 17em;
}
.desktop-global-search.ui.search>.results .result .image+.content,
.mobile-global-search-bar.ui.search>.results .result .image+.content {
  margin-right: 3.5em;
}

.ui.category.search>.results .category>.name {
  max-width: 72px;
  width: auto;
  white-space: normal;
  font-family: reader-regular, Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
  font-size: .9em;
}

.ui.search>.results .result {
  font-size: .9em;
  word-break: break-word;

}

.desktop-global-search.ui.search>.results .result .image {
  width: 3em;
  height: 3em;
}

.mobile-global-search-bar.ui.search>.results .result .image {
  width: 3em;
}

.desktop-global-search.ui.category.search>.results .category .results {
  max-width: 170px;
}

.mobile-global-search-bar.ui.category.search>.results .category .results {
  max-width: 150px;
}

.mobile-global-search-bar .ui.icon.input>input {
  background: transparent;
  color: white;
  width: 256px;
  border-color: transparent;
}

.mobile-global-search-bar .ui.icon.input>i.icon {
  color: #fff;
}

.sidebar.icon {
  font-size: 1.7em;
  color: white;
}

.ui.pointing.secondary.menu .mobile-logo {
  width: 50%;
  padding-bottom: 0;
  padding-top: 0;
}

.pusher .ui.secondary.inverted.pointing.menu .left.item {
  padding-left: 0
}

.pusher .ui.secondary.inverted.pointing.menu .right.item {
  padding-right: 0
}

.pusher .ui.secondary.pointing.menu .item {
  align-self: center!important;
}

@media only screen and (max-width: 767px) {
  .desktop-navigation.welcome-form .alumni-logo-desktop {
    margin-left: 29px;
    }
}