.pages {
  height: 100%;
  padding-bottom: 200px;
  margin-top: -15px;
}

.alumni-white path {
  fill: #fff;
}

.alumni-red path {
  fill: #bc1f2f;
}

.alumni-logo-desktop {
  width: 126.27px;
  margin: auto auto auto 0;
}

.alumni-logo-mobile {
  width: 50px;
}

h3.ui.header {
  letter-spacing: 0.02em;
  font-size: 2.67em;
}

.home-page h3.ui.header {
  color: #bc1f2f;
  margin-bottom: 10px;
  font-size: 2.7em;
}

.ui.container.welcome-section-overlay {
  width: auto;
}

.welcome-section-overlay {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(193, 2, 47, 0.8);
  z-index: 2;
}

.welcome-section {
  text-align: center;
  position: relative;
}

.pusher .pages .welcome-section {
  margin-top: -11px;
}

.welcome-image-section {
  z-index: 1;
}

.welcome-text {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.pages .welcome-text .welcome-header-text {
  font-size: 5.5em;
  line-height: 1.2em;;
  margin-top: 0;
  font-family: reader-bold-web, Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
  margin-bottom: 0;
}

.pages .welcome-section-overlay p {
  line-height: 1.25em;
  font-size: 1.2em;
  margin: auto;
}
.pages .welcome-section-overlay .welcome-sub-text {
  width: 450px;
}

.pages .welcome-section-overlay .welcome-sub-text-connected {
  margin-top: 1.5em;
  letter-spacing: 2px;
  font-family: reader-bold-web, Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
}

.home-page .segment, .share-page .segment {
  text-align: center;
  padding: 4.8em 0em 0em;
}

.home-page .your-network-section {
  padding-top: 60px;
  padding-bottom: 45px;
}

.home-page .your-network-section .network-images {
  min-width: 175px;
}

.home-page .your-network-section .network-image-tiles {
  background-size: contain;
  background-position-y: center;
  max-width: 175px;
}

.home-page .home-page-subheaders {
  font-size: 1.12em;
  max-width: 637px;
  margin: auto;
  line-height: 1.25em;
}

.home-page .home-page-buttons {
  margin-top: 30px;
}

.home-page .your-alumni-section {
  padding-top: 60px;
}

.home-page .ui.grid.your-alumni-section .column.network-images {
  min-width: 177px;
  padding: 7.5px !important;
}

.home-page .image-detail-text {
  padding-left: 10px;
  padding-top: 10px;
  text-align: left;
}

.home-page .your-alumni-section .image-detail-text h3 {
  text-transform: uppercase;
  font-size: 1.6em;
  line-height: 1.14em;
  overflow: hidden;
}

.home-page .your-alumni-section .image-detail-text h3.decreaseFontSize {
  font-size: 1.13em;
}

.home-page .image-detail-text p {
  margin-bottom: 2px;
}

.alumni-image-tile {
  height: 177.2px;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  color: white;
}

.welcome-image-section .welcome-image-tiles {
  height: 13vw;
  width: 16.665%;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
}

.welcome-image-section .remove-column-padding {
  padding: 0 !important;
}

.home-page .section.divider {
  margin-top: 6rem;
}

.home-page .stay-in-the-know-section .socials .social-links {
  padding-bottom: 15px;
  height: 45px;
}

.home-page .stay-in-the-know-section .socials .social-links i {
  font-size: 1.3em;
  color: #ababab;
  margin-right: 6px;
  padding-right: 5px;
  vertical-align: middle;
}

.home-page .stay-in-the-know-section .socials .social-logo {
  display: inline-block;
  height: 27px;
  max-width: 180px;
  width: auto;
  top: 0;
}
.home-page .stay-in-the-know-section .socials .social-name
 {
  display: inline-block;
  color: black;
  font-size: 16px;
  padding-bottom: 7px;
  font-weight: 600;
  vertical-align: top;
}

.home-page .stay-in-the-know-section .socials .socials-description {
  padding-bottom: 25px;
  min-height: 125px;
}

.home-page .stay-in-the-know-section .socials .socials-container {
  height: 100%;
  margin: auto;
}

.home-page .stay-in-the-know-section .socials {
  padding-top: 5em;
}

.home-page .share-celebrate-info {
  padding-top: 5em;
}

.home-page .share-celebrate-info a {
  color: #323e48;
  text-decoration: underline;
}

.home-page .share-celebrate-info .icon {
  color: lightgray;
}

.home-page .share-and-celebrate-icons {
  width: 66px;
  height: 66px;
}

.network-page h3.ui.header {
  color: #bc1f2f;
  font-size: 2.67em;
  letter-spacing: 0.05em;
  font-weight: bolder;
}

.network-page .network-image-detail-section .add-padding {
  padding-right: 20px;
}

.network-page .section-divider,
.events-page .section-divider,
.careers-page .section-divider {
  padding-top: 10px;
  padding-bottom: 10px;
}

.network-page .network-image-detail-section .image-bio-text h3 {
  margin-bottom: 10px;
  font-size: 1.7rem;
}

.network-page .network-image-detail-section .image-bio-text p {
  line-height: 1.35em;
}

.network-page .network-image-detail-section .social-text-section.questions h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.network-page .network-image-detail-section .social-text-section.questions h3:not(:first-child) {
  padding-top: 35px;
  margin-top: 0;
}

.network-page .network-image-detail-section .social-text-section.questions p {
  margin-bottom: 9px;
}

.network-page .network-images-section .column.network-images {
  padding-left: 7.5px !important;
  padding-right: 7.5px !important;
}

.network-page .edit-profile-button-container {
  padding-top: 63px;
}

.network-images-section .network-images {
  min-width: 175px;
  padding-bottom: 15px;
}

/* force visibility on some profiles */
.network-images-section .network-images.initiallyVisible .animated{
  opacity: 1!important;
}

.network-images-section .network-images .network-tile {
  width: 177.5px;
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.network-images-section .network-images .network-tile:hover {
  color: #bc1f2f;
}

.network-page .network-images-segment {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 16px;
}

.pages .ui.input, .careers-search-segment .ui.input {
  width: 100%;
}
.pages .ui.input.focus > input,
.pages .ui.input > input:focus {
  border-radius: 1.5em;
  padding-top: 4px;
  padding-bottom: 4px;
  border-color: #323e48;
}

.network-search-segment input::-webkit-input-placeholder {
  color: #323e48!important;
}
.pages .search.search-input.content-type-search-bar input {
  height: 2.1em;
}
.pages .ui.default.dropdown:not(.button) > .text,
.pages .ui.dropdown:not(.button) > .default.text {
  color: #fff;
}

.pages .ui.selection.dropdown {
  color: #fff;
  min-width: 100%;
  min-height: 2em;
  padding-top: 0.48571429em;
  padding-bottom: 0;
  background: #353839;
  border-radius: 1.19rem;
  border-color: #353839;
  text-transform: uppercase;
  width: max-content;
  padding-left: .8em;
}

.pages .ui.selection.dropdown.year-filter {
  width: 140.61px;
}

.pages .ui.active.visible.selection.dropdown {
  line-height: inherit;
}

.pages .ui.selection.active.dropdown:hover {
  box-shadow: none;
  border-color: transparent;
}

.pages .dropdown-min-width {
  min-width: 205px !important;
}

.pages .ui.selection.dropdown .icon {
  padding-top: .57em;
}

.pages .ui.selection.dropdown .menu > .item {
  border-color: #353839;
  color: #fff;
}

.pages .ui.search.dropdown.active > input.search,
.pages .ui.search.dropdown.visible > input.search {
  color: #fff;
}

.network-page .image-text-container {
  width: 175px;
  height: 100%;
  cursor: pointer;
  margin: auto;
}

.network-page .image-footer-text {
  margin: auto;
  overflow: hidden;
}

.network-page .image-footer-text h3 {
  margin-top: 0;
}

.network-page .single-image-detail-text h1,
.events-page .event-information h1 {
  font-family: reader-black-pro, Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
  line-height: 1.1em;
}


.network-page .single-image-detail-text .first-name,
.network-page .single-image-detail-text .last-name {
  color: #bc1f2f;
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 1.2em;
}

.network-page .single-image-detail-text .first-name {
  margin-bottom: 0;
  margin-top: 20px;
}

.network-page .single-image-detail-text .last-name {
  margin-top: 0;
}


.back-to-results.ui.labeled.icon.button {
  padding-top: 0.45em;
  padding-bottom: 0.45em;
  padding-left: 41.7px !important
}

.back-to-results .back-arrow-icon {
  width: 3.4em;
}

.pages .ui.labeled.icon.button > .icon {
  background: transparent;
}

.network-image-tiles {
  height: 175px;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
}

.network-page .network-images .image-footer-text {
  text-align: left;
  padding: 10px;
  padding-left: 0px;
}

.network-page .network-images .image-footer-text .first-name,
.network-page .network-images .image-footer-text .last-name {
  text-transform: uppercase;
  font-size: 1.4em;
}

.network-page .network-images .image-footer-text .first-name {
  margin-bottom: 0;
}

.network-page .network-images .image-footer-text .last-name {
  margin-bottom: 5px;
}

.network-page .network-images .image-footer-text .city {
  margin-bottom: 20px;
}

.network-page .network-image-detail-section .social-icons-section {
  position: relative;
}

.network-page .network-image-detail-section .social-icons-section .social-icon-item {
  position: absolute;
  bottom: 0;
}

.network-page .network-image-detail-section .social-icons-section i.icon {
  font-size: 1.59em;
  color: #323e48;
  margin-top: 43px;
  margin-left: 20px;
  margin-right: 0px;
}

.network-page .network-image-detail-section .social-icons-section .email-icon {
  width: 23.8px;
  display: inline-block;
  margin-top: 45px;
}

.network-page .profile-text {
  padding-top: 0;
}

.network-page .bio-social-details {
  margin-top: 0;
}

.network-page .years-text {
  margin-top: 30px;
}

/* .network-page .favorite-beings-image {
  width: 180px;
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
} */

.search-result-image-tile {
  height: 250px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  max-width: 250px;
}

.network-page .favorite-beings-section {
  padding-top: 35px;
}

.network-page .favorite-beings-section .photos {
  margin-top: 15px;
}

.network-page .additional-photos {
  cursor: pointer;
}

.news-article-image-tile {
  height: 400px;
  width: 100%;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.news-page .read-original-story-container {
  text-align: right;
  margin: auto;
  min-width: 219px;
}

.news-page .news-detail h3 {
  font-size: 1.4em;
  font-family: reader-bold-web, Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
  line-height: 1.2em;
  margin-bottom: 5px;
}

.news-page .news-detail p {
  margin-bottom: 5px;
}

.news-page .news-detail .story-content {
  color: #ababab;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.home-page .home-page-buttons,
.pages .back-to-results,
.news-page .read-original-story-button,
.news-page .read-original-story-container button,
.events-page .event-page-button,
.events-page .event-page-button-container button,
.careers-page .careers-button,
.careers-page .careers-apply-button,
.network-page .edit-profile-button,
.share-page .profile-buttons {
  background-color: transparent;
  border: 1px solid #323e48;
  border-radius: 2em;
  color: #323e48;
  padding-top: .45em;
  padding-bottom: .45em;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: normal;
  line-height: unset;
  font-size: 1.1rem;
}

.news-page .read-original-story-button.ui.labeled.icon.button {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}

.news-page .news-image-container {
  min-width: 150px;
}

.news-page .news-image-tile {
  height: 150px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  /* margin: auto; */
  max-width: 150px;
}

.news-segment-divider.ui.divider:not(.vertical):not(.horizontal),
.events-segment-divider.ui.divider:not(.vertical):not(.horizontal),
.careers-segment-divider.ui.divider:not(.vertical):not(.horizontal) {
  border-top-color: rgba(34,36,38,.15);
  border-bottom-color: rgba(34,36,38,.15);
}

.pages .events-page .ui.selection.active.dropdown .menu,
.pages .network-page .ui.selection.active.dropdown .menu,
.pages .careers-page .ui.selection.active.dropdown .menu {
  border-color: #353839 !important;
  border-radius: 0px;
  box-shadow: none;
}

.pages .events-page .ui.selection.dropdown .menu > .item,
.pages .network-page .ui.selection.dropdown .menu > .item,
.pages .careers-page .ui.selection.dropdown .menu > .item {
  background: #353839;
  border-color: #353839;
  color: #fff !important;
}

.news-page .read-orignal-story {
  padding-top: 25px;
}

.events-page .event-details {
  padding-bottom: 200px;
}

.events-page .events-dropdown {
  float: left;
}

.events-page .events-images-section {
  padding-top: 16px;
}

/*dropdown filter, year filter, and social select icon*/
.pages .ui.selection.dropdown i.icon {
  width: 1.6em;
  color: #fff;
  transform: rotate(-90deg);
  padding-top: 0;
  padding-right: 0;
  opacity: unset;
  padding-bottom: 21px
}

.ui.dropdown>.dropdown.icon:before {
  content: url('../images/whiteBackArrow.svg');
}

.ui.dropdown>.clear.icon:before {
  content: "\f00d";
}

.events-page .image-text-container {
  border-bottom: 1px solid rgba(34,36,38,.15);
  height: 100%;
  width: 100%;
  margin: auto;
  padding-bottom: 108px;
}

.events-page .image-container {
  height: 353px;
}

.events-page .event-images-section {
  margin-top: 30px;
}

.events-page .network-images {
  min-width: 250px;
}

.events-page .event-detail-text {
  padding-top: 20px;
}

.events-page .event-information h1 {
  font-size: 2.5rem;
  color: #bc1f2f;
  margin-top: 20px;
}

.events-page .event-information .event-description h3 {
  margin-bottom: 10px;
}

.events-page .heading-text {
  text-transform: uppercase;
}

.events-page .heading-text h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.events-page .event-date {
  padding-top: 10px;
  margin-bottom: 5px;
}

.events-page .tile-toggle.ui.left.attached.button,
.events-page .calendar-toggle.ui.right.attached.button {
  color: #323e48;
  padding-top: 0.49571429em;
  padding-bottom: 0.48571429em;
  background: transparent;
  border: 1px solid #353839;
  text-transform: uppercase;
  font-weight: normal;
}

.events-page .tile-toggle.ui.left.attached.button {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  padding-left: 15px;
}

.events-page .calendar-toggle.ui.right.attached.button {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding-right: 15px;
}

.events-page .ui.right.attached.button:active,
.events-page .ui.left.attached.button:active {
  color: #fff;
  background: #353839;
}

.events-page .rbc-calendar  .rbc-row-segment .rbc-event-content {
  color: #000;
}

.events-page .rbc-toolbar .rbc-toolbar-label {
  padding-right: 206px;
}

.events-page .ui.right.attached.button:hover,
.tile-toggle.ui.left.attached.button:hover {
  background-color: #cacbcd;
  background-image: none;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  color: rgb(0, 0, 0);
}

.events-button {
  padding-bottom: 2.5em;
  padding-top: 2.5em;
  position: absolute;
  bottom: 1.5em;
}

.events-page .read-original-story-container {
  padding-top: 25px;
}

.events-page .read-original-story-button {
  min-width: 125px;
}

.events-page .read-original-story-container.add-bottom-padding {
  padding-bottom: 100px;
}

.events-page .events-button button {
  min-width: 120px;
}

.events-page .event-page-button-container {
  min-width: 142.64;
  text-align: right;
}

.events-page .network-image-tiles {
  height: 100%;
  width: 100%;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}


.careers-page .single-image-detail-text .company-title {
  text-transform: uppercase;
  font-size: 2.5rem;
}

.careers-page .jobs-post-text {
  padding-top: 30px;
}

.careers-page .careers-button-container {
  text-align: right;
}

.careers-page .careers-posting {
  min-height: 180px;
}

.careers-page .careers-image-container .careers-image-tile {
  height: 130px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 130px;
}

.careers-page .career-detail-image .careers-image-tile {
  height: 225px;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 225px;
}

.careers-page .jobs-detail h3 {
  margin-bottom: 0px;
  font-family: reader-bold-web, Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
  line-height: 1.2em;
}

.careers-page .careers-page-button-container {
  text-align: right;
}

.careers-page .careers-page-dividers {
  border-top: rgba(34,36,38,.15);
}

.careers-page .jobs-post-text h3 {
  margin-bottom: 10px;
}

.rbc-btn-group button {
  cursor: pointer;
}

.share-page h3.ui.header {
  max-width: 460px;
  margin: auto;
  color: #bc1f2f;
  line-height: 1.1em;
  padding-top: 50px;
}

.share-page .form-container,
.edit-profile-page .form-container,
.edit-jobs-page .form-container {
  border: 1px solid #000000;
  padding: 50px 40px;
  height: 100%;
}

.share-page .share-forms-section {
  padding-top: 30px;
  margin-left: 100px;
  margin-right: 100px;
}

.share-page .ui.stacked.segment.form-fields {
  padding-top: 35px;
}

.share-page .ui.stacked.segment.form-fields .field {
  margin-bottom: 20px;
}

.share-page .form-container.job-posting .form-fields {
  padding-top: 16px !important;
}

.share-page .ui.stacked.segment.form-fields,
.edit-profile-page .ui.stacked.segment.form-fields {
  border: 0;
  box-shadow: none;
  background: rgba(255, 255, 255, 0) !important;
}

.caption-input {
  margin-top: 10px;
}

.share-page .event-posting .inline-upload .input .basic.label {
  border-left: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-radius: 0;
  cursor: pointer;
  height: 38px;
  padding-top: 8px;
}

.share-page .event-posting .inline-upload .input .basic.label .upload-icon {
  margin-right: 2px;
}
.share-page .ui.form .field input,
.share-page .ui.form .field .ui.input input,
.share-page .ui.form .fields .field .ui.input input,
.edit-profile-page .ui.form .field .ui.input input,
.edit-profile-page .ui.form .fields .field .ui.input input,
.edit-jobs-page .ui.form .field .ui.input input,
.edit-jobs-page .ui.form .fields .field .ui.input input {
  background: rgba(255, 255, 255, 0);
  border: 1px solid #000000;
  border-radius: 0;
}

.share-page .ui.form .field > label,
.edit-profile-page .ui.form .field > label,
.edit-jobs-page .ui.form .field > label
 {
  color: #323e48;
  text-align: left;
  font-weight: 300;
  font-size: 13px;
}

.share-page .share-forms-section h1.ui.header {
  font-size: 2.5rem;
  padding-top: 25px;
}

.share-page h1.share-header .sub.header,
.ui.message .header,
.ui.message+p {
  font-family: reader-regular, Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
}

.share-page h1.share-header .sub.header {
  font-size: 1.1114rem;
}

.share-page .share-header .sub.header.sub-header-one {
  padding-top: 8px;
}

.share-page .share-header .sub.header {
  color: #323e48;
}

.share-page .ui.form .field .ui.input input {
  height: 38px;
}

.share-page .ui.error.message {
  color: #bc1f2f;
}

.share-page .vertical.segment {
  padding-top: 0;
}

.share-page .ui.button:focus {
  background-color: transparent;
}

.edit-profile-page .ui.small.button,
.edit-jobs-section .ui.small.button {
  border: 1px solid #323e48;
  background: rgba(255, 255, 255, 0);
  border-radius: 2em;
  color: #323e48;
  font-weight: 300;
  margin-top: 30px;
}

.share-page .ui.form textarea,
.edit-profile-page .ui.form textarea,
.edit-jobs-page .ui.form textarea
 {
  border: 1px solid #000000;
}

.share-page .ui.form textarea {
  border-radius: 0;
}

.share-page .ui.form textarea:not([rows]) {
  height: 75px;
}

.share-page .share-page-subheaders,
.edit-profile-page .share-page-subheaders {
  font-size: 1.12em;
  max-width: 637px;
  margin: auto;
  padding-top: 10px;
}

.share-page .share-page-subheaders.sub-one {
  padding-top: 3s0px;
}

.share-page .share-page-subheaders.sub-two {
  padding-bottom: 50px;
}

.share-page .image-upload-container label {
  position: relative;
}

.share-page .image-upload-container label input {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.share-page .ui.stacked.segment:after {
  background: none;
  width: 0;
  height: 0;
}

.share-page .profile-buttons {
  margin-top: 30px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.edit-profile-page .edit-header,
.edit-jobs-page .edit-header {
  text-align: center;
}

.edit-profile-page .image-uploaded {
  padding-bottom: 25px;
}

.edit-jobs-page .form-fields {
  text-align: center;
}

.text-wrap {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.about-page,
.contact-page,
.privacy-policy-page,
.terms-of-use-page,
.no-profile-page,
.no-career-post-page,
.no-event-page,
.profile-not-found-page {
  padding-top: 60px;
  min-height: 667px;
}

.about-page h3.ui.header,
.contact-page h3.ui.header,
.privacy-policy-page h3.ui.header,
.terms-of-use-page h3.ui.header,
.no-profile-page h3.ui.header,
.no-career-post-page h3.ui.header,
.no-event-page h3.ui.header,
.profile-not-found-page h3.ui.header {
  text-align: center;
  text-transform: uppercase;
}

.about-page .about-page-subheaders,
.contact-page .contact-page-subheaders,
.no-profile-page .no-profile-page-subheaders {
  font-size: 1em;
  max-width: 500px;
  margin: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}

.about-page .stay-connected {
  padding-top: 25px;
}

.about-page .about-page-subheaders .stay-connected,
.about-page .about-page-subheaders .welcome {
  font-family: reader-black-pro, Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
  letter-spacing: 2px;
}

.about-page .ui.section.divider {
  margin-top: 90px;
  margin-bottom: 90px;
}

.privacy-policy-page .privacy-policy-page-subheaders {
  font-size: 1em;
  margin: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

.privacy-policy-page .policy-items {
  text-decoration: underline;
}

.terms-of-use-page .otnotice-content .otnotice-sections .otnotice-section-content ul {
  padding-left: 40px;
  font-size: .8rem;
}

.privacy-policy-page .otnotice-content .otnotice-sections .otnotice-section-content ul {
  padding-left: 40px;
  font-size: .8rem;
}

.terms-titles {
  font-weight: 700;
}

#SRLLightbox {
  z-index: 300;
}

.welcome-section {
  padding: 0em 0em;
  height: 0;
  padding-top: 57.41%;
  position: relative;
  color: white;
}

.carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.carousel .arrow {
  opacity: 0.5;
}

.carousel__dot-group {
  position: absolute;
  width: 500px;
  bottom: 10px;
  left: calc(50% - 250px);
}

.carousel__dot-group button {
  margin: 0 2px;
  padding: 5px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid rgba(208, 208, 208, .5);
  opacity: .5;
}

.carousel__dot-group button:hover {
  border: 0;
  background-color: #cccccc;
}

.carousel__dot-group button:disabled,
.carousel__dot-group button[disabled] {
  border: 0;
  background-color: #bc1f2f;
}

.carousel__dot-group button:focus {
  border: 0;
  background-color: #bc1f2f;
  outline:none;
}

button.carousel__back-button, button.carousel__next-button {
  color: white;
  position: absolute;
  width: 30px;
  height: 100px;
  top: calc(50% - 50px);
  background: transparent;
  border: 0;
  font-size: 4em;
}

button.carousel__back-button {
  left: 2px;
}

button.carousel__next-button {
  right: 2px;
}

button.carousel__back-button:hover polygon, button.carousel__next-button:hover polygon {
  fill: #bc1f2f !important;
}

button.carousel__back-button:focus, button.carousel__next-button:focus {
  border: 0;
  outline:none;
}

.pusher .pages .welcome-section-overlay {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.pusher .pages .welcome-image-section {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: auto!important;
}

/*media queries*/
@media only screen and (max-width: 767px) {
  .welcome-text {
    zoom: 70%;
  }

  .pages .welcome-text .welcome-header-text {
    font-size: 4.5em;
    line-height: 1em;
  }
  .careers-page .careers-button-container {
    text-align: left;
    padding-bottom: 20px;
  }
  .news-page .read-original-story-container {
    text-align: center;
  }
  .events-page .tile-calendar-toggle-container {
    float: none !important;
  }
  .network-page .search-result-image-tile {
    max-width: 250px;
  }
}

@media only screen
  and (min-device-width: 737px)
  and (max-device-width: 823px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) {
    .welcome-text {
      zoom: 85%;
    }
}


@media only screen
  and (min-width: 768px)
  and (max-width: 899px) {

  .pages .ui.selection.dropdown {
    min-width: 100%;
  }
}

@media only screen and (max-width: 944px) and (min-width: 768px) {
  .welcome-text {
    zoom: 74%;
  }
}

@media only screen and (max-width: 990px) {
  .network-page .search-result-image-tile {
    margin: unset;
  }
}

@media only screen and (min-width: 1200px) {
  .desktop-navigation .navigation-menus {
    margin-right: 0px !important;
  }

  .home-page .alumni-image-tile {
    height: 216px;
    max-width: 216px;
  }
}

@media only screen and (max-width: 321px) {
  .home-page .stay-in-the-know-section .socials .social-logo {
    max-width: 104px;
    height: 20px;
  }
  .pages .welcome-text .welcome-header-text {
    font-size: 3em;
    line-height: 1em;
  }
}
@media only screen
  and (max-width: 375px)
  and (min-width: 322px) {
  .home-page .stay-in-the-know-section .socials .social-logo {
    max-width: 129px;
    height: 20px;
  }
  .home-page .stay-in-the-know-section .socials .social-name {
    font-size: 14px;
  }

  .pages .welcome-text .welcome-header-text {
    font-size: 4em;
    line-height: 1em;
  }
}

@media only screen and (max-width: 415px) {
  .pages .ui.selection.dropdown {
    min-width: 100%;
    margin: auto;
  }
  .network-page .network-images-section .network-images .network-tile {
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }
  .network-page .network-tile .alumni-image-tile {
    height: 172.5px;
  }
  /* .welcome-section {
    height: 100vw !important;
  } */
  .ui.container.welcome-section-overlay {
    margin-left: 0px!important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 767px) and (min-width: 416px) {
  .network-page .network-images-section .alumni-image-tile {
    height: calc((100vw - 73px) / 2);
  }
  .network-images-section .network-images .network-tile {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 767px) {
  /* semantic grid override for home alumni section */
  .home-page .ui.doubling.grid>.row>.column {
    padding-top: 7.5px !important;
    padding-bottom: 7.5px !important;
  }
  .welcome-image-section .welcome-image-tiles {
    height: 22vw;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .network-page .network-images-section .network-images .network-tile {
    width: 221px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .network-page .network-images-section .alumni-image-tile {
    height: 221px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .events-page .image-container {
    height: 229px;
  }
  .events-page .image-text-container {
    width: 229px;
  }
}

@media only screen and (max-width: 1999px) and (min-width: 945px) {
  .welcome-text {
    zoom: 92%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1474px) {
  .network-page .network-images-section .network-images .network-tile {
    width: 175.3px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .network-page .search-result-image-tile {
    height: 250px;
  }
  .events-page .image-container {
    height: 267.75px;
  }
  .events-page .image-text-container {
    width: 267.75px
  }
}
@media only screen and (min-width: 1475px) {
  .ui.container {
    width: 1400px;
  }
  .ui.container.welcome-image-section {
    width: calc(1475px + 2rem) !important;
  }
  .home-page .alumni-image-tile {
    height: 282px;
    max-width: 282px;
  }
  .home-page .your-alumni-section .image-detail-text h3 {
    font-size: 2.6em;
  }
  .home-page .your-alumni-section .image-detail-text h3.decreaseFontSize {
    font-size: 1.8em;
  }
  .network-page .network-images .alumni-image-tile {
    height: 228.3px;
  }
  .network-images-section .network-images .network-tile {
    width: 228.3px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .network-page .search-result-image-tile {
    height: 300px;
    max-width: 100%;
  }
  .events-page .image-container {
    height: 355px;
  }
  .events-page .image-text-container {
    width: 355px;
  }
  .events-page .news-article-image-tile {
    height: 600px;
  }
}

/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .pages .welcome-text .welcome-header-text {
      font-size: 2.5em;
      line-height: 1em;
    }

    .pages .welcome-text .welcome-sub-text {
      font-size: 1.1em;
      line-height: 1em;
    }

    .home-page .stay-in-the-know-section .socials .social-logo {
      max-width: 104px;
      height: 20px;
    }
    .home-page .stay-in-the-know-section .socials .social-name {
      font-size: 14px;
    }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2) {

    .pages .welcome-text .welcome-header-text {
      font-size: 2.5em;
      line-height: 1em;
    }

    .pages .welcome-text .welcome-sub-text {
      font-size: 1.1em;
      line-height: 1em;
    }

    .home-page .stay-in-the-know-section .socials .social-logo {
      max-width: 129px;
      height: 20px;
    }

    .home-page .stay-in-the-know-section .socials .social-name {
      font-size: 14px;
    }
}

/* iPhone 11 */
@media only screen
    and (device-width: 414px)
    and (device-height: 896px)
    and (-webkit-device-pixel-ratio: 2) {

  .pages .welcome-text .welcome-header-text {
    font-size: 2.5em;
    line-height: 1em;
  }

  .pages .welcome-text .welcome-sub-text {
    font-size: 1.1em;
    line-height: 1em;
  }

  .home-page .stay-in-the-know-section .socials .social-logo {
    max-width: 129px;
    height: 20px;
  }

  .home-page .stay-in-the-know-section .socials .social-name {
    font-size: 14px;
  }
}

/* iPhone 11 Pro */
@media only screen
    and (device-width: 375px)
    and (device-height: 812px)
    and (-webkit-device-pixel-ratio: 3) {

    .pages .welcome-text .welcome-header-text {
      font-size: 2.5em;
      line-height: 1em;
    }

    .pages .welcome-text .welcome-sub-text {
      font-size: 1.1em;
      line-height: 1em;
    }

    .home-page .stay-in-the-know-section .socials .social-logo {
      max-width: 129px;
      height: 20px;
    }

    .home-page .stay-in-the-know-section .socials .social-name {
      font-size: 14px;
    }
}

/* iPhone 11 Pro Max */
@media only screen
    and (device-width: 414px)
    and (device-height: 896px)
    and (-webkit-device-pixel-ratio: 3) {

    .pages .welcome-text .welcome-header-text {
      font-size: 2.5em;
      line-height: 1em;
    }

    .pages .welcome-text .welcome-sub-text {
      font-size: 1.1em;
      line-height: 1em;
    }

    .home-page .stay-in-the-know-section .socials .social-logo {
      max-width: 129px;
      height: 20px;
    }

    .home-page .stay-in-the-know-section .socials .social-name {
      font-size: 14px;
    }
}