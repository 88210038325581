.landing {

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

.login-signup-page.background-color {
  background-color: #bc1f2f;
}

.login-signup-page .alumni-logo-desktop {
  margin-top: 23px;
}
.landing .forms-section {
  padding-top: 30px;
  margin-left: 50px;
  margin-right: 50px;
}

.landing .form-height {
  height: auto;
}

.landing .form-container h1.ui.header {
  font-size: 2.5rem;
  font-family: reader-black-pro, Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
}
.landing .form-container .login-header {
  color: #fff;
  text-align: center;
}

.landing .form-container .login-header .sub.header {
  color: #fff;
  padding: 15px 50px 0px;
  font-family: reader-regular, Arial, 'Helvetica Neue', Helvetica, sans-serif !important;
}

.landing .form-container {
  border: 1px solid #fff;
  padding: 50px 40px;
  height: 100%;
}

.landing .segment {
  text-align: center;
  padding: 4.8em 0em 0em;
}

.landing .ui.stacked.segment.form-fields {
  border: 0;
  box-shadow: none;
  background: rgba(255, 255, 255, 0) !important;
  padding-top: 35px;
}

.landing .ui.stacked.segment:after {
  border: 0;
  height: 0;
}

.landing .ui.form .field {
  margin-bottom: 20px;
}

.landing .ui.form .field > label {
  color: white;
  text-align: left;
  font-weight: 300;
  font-size: 13px;
}

.landing .ui.form .field .ui.input input,
.landing .ui.form .fields .field .ui.input input {
  background: rgba(255, 255, 255, 0);
  border: 1px solid #fff;
  border-radius: 0;
  color: white;
  height: 38px;
}

.landing .ui.form .field.field input:-webkit-autofill {
  background: rgba(255, 255, 255, 0);
}

.landing .ui.form .two.fields .location-worked {
  width: 100%;
}

.landing .ui.small.button.landing-buttons {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 2em;
  color: #fff;
  padding-top: .45em;
  padding-bottom: .45em;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: normal;
  line-height: unset;
  margin-top: 30px;
  width: 177.688px;
  font-size: 1.1rem;
}

 /* Chrome, Safari, Edge, Opera */
.landing input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.landing input[type=number] {
  -moz-appearance: textfield;
}

/*media queries*/

@media only screen and (max-width: 767px) {
  .login-signup-page .alumni-logo-desktop {
    margin-left: 29px;
  }
}
@media only screen and (max-width: 415px) {
  .landing .form-container .login-header .sub.header {
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .landing .ui.form .fields .start-year,
  .landing .ui.form .fields .end-year {
    margin-top: 19px;
  }
}

@media only screen and (max-width: 320px) {
  .landing .form-container {
    padding-right: 20px;
    padding-left: 20px;
  }
}