.App {
  height: 100%;
}
.App .caa-logo {
  color: white;
  background: #bc1f2f;
}
.App .fade-enter {
    opacity: 0.01;
  }
.App .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

.App .fade-exit {
    opacity: 1;
  }

.App .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }

