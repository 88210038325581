@font-face {
  font-family: reader-bold-web;
  src: url('/fonts/reader-bold-web.eot'), url('/fonts/reader-bold-web.ttf'), url('/fonts/reader-bold-web.woff'), url('/fonts/reader-bold-web.woff2');
}
@font-face {
  font-family: reader-medium-web;
  src: url('/fonts/reader-medium-web.eot'), url('/fonts/reader-medium-web.ttf'), url('/fonts/reader-medium-web.woff'), url('/fonts/reader-medium-web.woff2');
}
@font-face {
  font-family: reader-regular;
  src: url('/fonts/reader-regular.eot'), url('/fonts/reader-regular.ttf'), url('/fonts/reader-regular.woff');
}
@font-face {
  font-family: reader-black-pro;
  src: url('/fonts/reader-black-pro.eot'), url('/fonts/reader-black-pro.ttf'), url('/fonts/reader-black-pro.woff'), url('/fonts/reader-black-pro.woff2');
}
  
body {
  margin: 0;
  color:  #323e48;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: reader-regular, Arial, 'Helvetica Neue', Helvetica, sans-serif !important
}

h1, h2, h4, .ui.header, .rbc-event-content {
  font-family: reader-bold-web, Arial, 'Helvetica Neue', Helvetica, sans-serif !important
}

h3, .ui.header, .rbc-event-content {
  font-family: reader-black-pro, Arial, 'Helvetica Neue', Helvetica, sans-serif !important
}

h5, .ui.button, .ui.menu, .ui.input, .ui.input>input, .text {
  font-family: reader-regular, Arial, 'Helvetica Neue', Helvetica, sans-serif !important
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div#root {
  height: 100%;
}
